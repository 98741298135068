var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

import pick from 'pick-deep';

function strOp(str) {
  return str.toString().replace(/\s/g, '').toLowerCase();
}

function objectValues(value, pickAttr) {
  return (pickAttr ? Object.values(pick(value, pickAttr)) : Object.values(value)).reduce(function (string, val) {
    var test = val !== null && val !== undefined;
    return string + ((typeof val === 'undefined' ? 'undefined' : _typeof(val)) === 'object' && val !== null ? strOp(objectValues(val)) : test ? strOp(val) : '');
  }, '');
}

export function filter(val, data, pick) {
  return data.filter(function (el) {
    return !!val.length ? objectValues(el, pick).includes(strOp(val)) : true;
  });
}
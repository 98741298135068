function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import { Component } from 'react';
import PropTypes from 'prop-types';
import { filter } from './filter';

var FilterResults = function (_Component) {
  _inherits(FilterResults, _Component);

  function FilterResults() {
    _classCallCheck(this, FilterResults);

    return _possibleConstructorReturn(this, _Component.apply(this, arguments));
  }

  FilterResults.prototype.render = function render() {
    var _props = this.props,
        value = _props.value,
        data = _props.data,
        pick = _props.pick;

    return this.props.renderResults(filter(value, data, pick));
  };

  return FilterResults;
}(Component);

export { FilterResults as default };


FilterResults.propTypes = process.env.NODE_ENV !== "production" ? {
  value: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderResults: PropTypes.func.isRequired,
  pick: PropTypes.arrayOf(PropTypes.string)
} : {};